<nav class="navbar navbar-expand-md">
  <a class="navbar-brand" href="#">HAPPY BIRTHDAY TO MY QUEEN</a>
  <button class="lg">
    Login
    <div>
      <a href="#" data-toggle="modal" data-target="#wife-login">KARISHMA</a>
      <a href="#" data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#other-login">FAMILY &
        FRIENDS</a>
    </div>
  </button>
</nav>
<!-- <iframe src="assets/audio/tune4.mp3"  allow="autoplay" style="display:none" id="iframeAudio"></iframe> -->

<!-- <audio autoplay loop>
  <source src="assets/audio/tune4.mp3">
</audio> -->
<section class="slider slide carousel-fade" data-pause="true" id="demo" data-ride="carousel" data-interval="2500">
  <div class="carousel-inner kk">
    <div class="carousel-item active each-slider">
      <!-- <div class="slide-wrap">
            <h1>Hello World</h1>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
        </div> -->

      <img src="assets/images/001.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <!-- <div class="slide-wrap">
            <h1>Hello World</h1>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
        </div> -->
      <img src="assets/images/002.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <!-- <div class="slide-wrap">
            <h1>Hello World</h1>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
        </div> -->
      <img src="assets/images/003.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <!-- <div class="slide-wrap">
            <h1>Hello World</h1>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
        </div> -->
      <img src="assets/images/004.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <!-- <div class="slide-wrap">
          <h1>Hello World</h1>
          <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
      </div> -->
      <img src="assets/images/005.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <!-- <div class="slide-wrap">
        <h1>Hello World</h1>
        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
    </div> -->
      <img src="assets/images/006.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <!-- <div class="slide-wrap">
      <h1>Hello World</h1>
      <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
  </div> -->
      <img src="assets/images/007.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <!-- <div class="slide-wrap">
      <h1>Hello World</h1>
      <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
  </div> -->
      <img src="assets/images/008.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <!-- <div class="slide-wrap">
      <h1>Hello World</h1>
      <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
  </div> -->
      <img src="assets/images/009.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <!-- <div class="slide-wrap">
      <h1>Hello World</h1>
      <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
  </div> -->
      <img src="assets/images/010.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <!-- <div class="slide-wrap">
      <h1>Hello World</h1>
      <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
  </div> -->
      <img src="assets/images/011.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <!-- <div class="slide-wrap">
      <h1>Hello World</h1>
      <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
  </div> -->
      <img src="assets/images/012.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <!-- <div class="slide-wrap">
      <h1>Hello World</h1>
      <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
  </div> -->
      <img src="assets/images/013.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <!-- <div class="slide-wrap">
      <h1>Hello World</h1>
      <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
  </div> -->
      <img src="assets/images/014.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <!-- <div class="slide-wrap">
      <h1>Hello World</h1>
      <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
  </div> -->
      <img src="assets/images/015.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <!-- <div class="slide-wrap">
      <h1>Hello World</h1>
      <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
  </div> -->
      <img src="assets/images/016.png" alt="">
    </div>
  </div>

</section>
<section class="res-slider slide carousel-fade" id="demo" data-ride="carousel" data-pause="true" data-interval="2500">
  <div class="carousel-inner">
    <div class="carousel-item active each-slider">
      <img src="assets/images/res-img1.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img2.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img3.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img4.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img5.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img6.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img7.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img8.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img9.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img10.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img11.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img12.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img13.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img14.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img15.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img16.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img17.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img18.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img19.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img20.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img21.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img22.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img23.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img24.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img25.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img26.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img27.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img28.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img29.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img30.png" alt="">
    </div>
    <div class="carousel-item each-slider">
      <img src="assets/images/res-img31.png" alt="">
    </div>
  </div>

</section>
<!-- The Modal -->
<div class="modal fade" id="wife-login">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- <img src="assets/images/bln.png" alt=""> -->
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title w-100 text-center">Login to your dashboard</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <img src="assets/images/cel2.png" alt="">
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form class="text-cneter">


          <input #password type="password" placeholder="Enter your password" id="pswd">
          <button class="lgin" (click)="logIn(password.value)">Login</button>
          <p *ngIf="showError" class="text-center my-2 text-danger">Incorrect password, please try again</p>
        </form>
        <img src="assets/images/cel.png" alt="">
      </div>




    </div>
  </div>
</div>
<div class="modal fade" id="other-login">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- <img src="assets/images/bln.png" alt=""> -->
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title w-100 text-center">Leave your wishes</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form class="text-cneter">
          <div class="txt-grp">
            <input type="text" [(ngModel)]="form.name" [ngModelOptions]="{standalone: true}"
              placeholder="Enter your name">
            <input type="email" [(ngModel)]="form.email" [ngModelOptions]="{standalone: true}"
              placeholder="Enter your mail">
          </div>

          <div class="radio-grp">
            <fieldset>
              <legend>Your relationship with her</legend>
              <label class="form-check-label">
                <input type="radio" class="form-check-input" value="NISCHAY" name="optradio"
                  [(ngModel)]="form.relationship" [ngModelOptions]="{standalone: true}" (change)="changeName($event)">NISCHAY
              </label>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" value="FAMILY" name="optradio"
                    [(ngModel)]="form.relationship" [ngModelOptions]="{standalone: true}">FAMILY
                </label>
              </div>
              <div class="form-check-inline">
                <label class="form-check-label">
                  <input type="radio" class="form-check-input" value="FRIENDS" name="optradio"
                    [(ngModel)]="form.relationship" [ngModelOptions]="{standalone: true}">FRIENDS
                </label>
              </div>

            </fieldset>


          </div>
          <!-- <input type="password" [(ngModel)]="form.password" [ngModelOptions]="{standalone: true}" (input)="familyPasswordCheck()"  placeholder="Enter your password"> -->
          <input type="password" [(ngModel)]="form.password" [ngModelOptions]="{standalone: true}"
            placeholder="Enter your password">

          <textarea [(ngModel)]="form.wish" [ngModelOptions]="{standalone: true}" placeholder="Leave your wish"
            class="form-control"></textarea>
          <!-- <button (click)="submit()">Demo check</button> -->
          <div class="btn-grp" *ngIf="true">
            <!-- <button id="fake"> <i class="fa fa-folder-open-o" aria-hidden="true"></i> Upload Media</button> -->

            <input class="my-4" *ngIf="!showLoader&&!fileuploded" type="file" id="" (change)="readURL($event)" multiple
              accept="video/mp4,video/x-m4v,video/*,image/png, image/gif, image/jpeg,image/*" />

            <div *ngIf="showLoader" class="spinner-border text-primary text-center p-4 m-3" role="status">
              <span class="sr-only">Loading...</span>
            </div>

            <p *ngIf="fileuploded" class="m-2 text-success"
              style="position: absolute; bottom: 0; width:100%; text-align:center">Files uploaded sucessfully</p>
            <!-- <img id="blah" [src]="imageSrc || 'http://placehold.it/180'" alt="your image" /> -->
            <button data-toggle="modal" (click)="preview()" data-target="#preview" [disabled]="!showPreview">Preview <i
                class="fa fa-eye" aria-hidden="true" data-backdrop="false"></i></button>
          </div>

          <!-- The Modal -->
          <div class="modal fade px-0" id="preview" style="padding: 0;">
            <div class="modal-dialog modal-dialog-centered" style="max-width: 100%; margin: 0 auto;">
              <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                  <h4 class="modal-title w-100 text-center">You are previwing <br> <p class="text-center" style="font-size: 14px; margin-top: 5px; color:#fff">Send the message from below</p></h4>
                  <span type="button" class="close" data-dismiss="modal"
                    style="position: absolute; top:10px;right:10px;color:#fff;opacity:1">&times;</span>
                </div>

                <div class="modal-body">
                  <div class="container">




                    <div class="wish-holder">
                      <header class="dash-header">

                        <h1 class="text-center">Here are your messages</h1>


                      </header>
                      <div class="container my-5">


                        <div class="row yy">
                          <div class="col-md-4 col-6 ap" *ngFor="let item of form.files;let i=index">

                            <img [src]="item" *ngIf="isImage(item)" (click)="showGallery(i)" [counter]=true />

                            <div class="strip" *ngIf="!isImage(item)">
                              <div class="film">
                                <div class="film__frame">

                                  <video width="300" height="300" id={{i}} controls (click)="video(i)">
                                    <source [src]="item" type="video/mp4">
                                    Your browser does not support the video tag.
                                  </video>
                                </div>

                              </div>




                            </div>
                            <!-- <video *ngIf="!item.isImage"  width="400" height="440" id={{i}} controls (click)="video(i)">
          <source [src]="item.url" type="video/mp4">
          Your browser does not support the video tag.
        </video> -->

                          </div>


                        </div>



                        <div class="explanation">










                          <div class="wish-text">
                            <img src="../../assets/images/wish.png" alt="" id="tp">
                            <!-- <h2>Message Heading</h2> -->
                            <p>{{form.wish}}</p>
                            <img src="../../assets/images/wish.png" alt="" id="bt">
                          </div>
                          <div class="sender-info">
                            <p>Sent by <span>{{form.name}}</span></p>
                          </div>
                        </div>



                      </div>

                    </div>


                  </div>

                  <!-- Modal footer -->
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="submit()"><i
                        class="fa fa-send"></i>Send Message</button>
                  </div>

                </div>



              </div>
            </div>
          </div>


        </form>










      </div>



    </div>
  </div>
</div>
