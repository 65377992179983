import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { Gallery } from 'angular-gallery';
import * as Notiflix from 'notiflix';
import { Observable } from 'rxjs/internal/Observable';
import { finalize } from 'rxjs/internal/operators/finalize';
import { WishService } from './../wish.service';


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit, AfterViewInit {
  @ViewChild('someInput') someInput!: ElementRef;
  inputText: string = "";
  showError = false;
  familyEmail = "senderEmail@gmail.com"
  email = "admin@admin.com"
  nisChayEmail="nischay@nischay.com"

  showPreview=false

  passwordMatch: boolean = false

  form = {
    name: "",
    email: "",
    password: "",
    relationship: "",
    wish: "",
    isOpen: false,
    files: [],


  }
  fileuploded: boolean = false
  showLoader: any = false;
  uploads: any[];
  downloadURLs: any[];
  uploadPercent: Observable<number>;
  filelist: any;

  constructor(private gallery: Gallery,private router: Router, private _service: WishService, private storage: AngularFireStorage) { }


  showGallery(index: number) {
    let prop = {
      images: this.form.files,
      index
    };
    this.gallery.load(prop);
  }


  ngOnInit() {








  }
  imageSrc: any;


  fileUpload(filelist) {
    debugger
    this.showLoader = true;
    // reset the array
    this.uploads = [];
    this.downloadURLs = [];
    const allPercentage: Observable<number>[] = [];
    try {
      for (const file of filelist) {
        const filePath = `files/${file.name} `;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, file);
        const _percentage$ = task.percentageChanges();
        allPercentage.push(_percentage$);

        // observe percentage changes
        this.uploadPercent = task.percentageChanges();

        // get notified when the download URL is available
        task.snapshotChanges().pipe(
          finalize(() => {
            fileRef.getDownloadURL().subscribe((url) => {
              console.log("url", url)
              this.downloadURLs = this.downloadURLs.concat([url]);
              console.log(this.downloadURLs)
              if (filelist.length === this.downloadURLs.length) {
                console.log("complete now with", this.downloadURLs)
                this.form.files = this.downloadURLs
                this.showLoader = false;
                this.fileuploded = true
                this.showPreview=true





              }
            });

          })
        ).subscribe();

      }
    }
    catch (error) {
      this.showLoader = false;

    }

  }

  readURL(event: any): void {

    this.filelist = event.target.files;
     this.fileUpload(this.filelist)



  }

  prev(event,a){
    console.log(a.href)
    event.preventDefault()



  }
  next(event,a:any){
    console.log(a.href)

    event.preventDefault()


  }

  logIn(password) {
    try {

      this._service.SignIn(this.email, password)

    } catch (error) {
      this.showError = true

    }


  }



  // familyPasswordCheck() {
  //   if (this.familyPassword == this.form.password) {
  //     this.passwordMatch = true

  //   }
  //   else {
  //     this.passwordMatch = false
  //   }

  // }




  submit() {
if(this.form.relationship=="NISCHAY"){
  if (this.form.password && this.form.wish) {
    this._service._otherSignIn(this.nisChayEmail, this.form.password).then(result => {


      try {

        this._service.create(this.form).then((a) => {
          console.log(a.key)
          console.log(`${window.location.hostname}/preview/${a.key}`)

          Notiflix.Notify.success("Successfully Sent");
          this.form = {
            name: "",
            email: "",
            password: "",
            relationship: "",
            wish: "",
            isOpen: false,
            files: []

          }

          this.fileuploded=false

        });


        // this.fileUpload(this.filelist)

        // this.form = {
        //   name: "",
        //   email: "",
        //   password: "",
        //   relationship: "",
        //   wish: "",
        //   isOpen: false,
        //   files: []

        // }
        // this.fileuploded = false;
        // this.showLoader = false;





      }
      catch (error) {
        console.log(error)


      }


    })
  }
  else {
    Notiflix.Notify.info("Please Enter Valid details");


  }

}
else{
  if (this.form.password && this.form.wish) {
    this._service._otherSignIn(this.familyEmail, this.form.password).then(result => {


      try {

        this._service.create(this.form).then((a) => {
          console.log(a.key)
          console.log(`${window.location.hostname}/preview/${a.key}`)

          Notiflix.Notify.success("Successfully Sent");
          this.form = {
            name: "",
            email: "",
            password: "",
            relationship: "",
            wish: "",
            isOpen: false,
            files: []

          }

          this.fileuploded=false

        });


        // this.fileUpload(this.filelist)

        // this.form = {
        //   name: "",
        //   email: "",
        //   password: "",
        //   relationship: "",
        //   wish: "",
        //   isOpen: false,
        //   files: []

        // }
        // this.fileuploded = false;
        // this.showLoader = false;





      }
      catch (error) {
        console.log(error)


      }


    })
  }
  else {
    Notiflix.Notify.info("Please Enter Valid details");


  }

}










  }


  // isImage(filename) {
  //   var ext = this.getExtension(filename);
  //   switch (ext.toLowerCase()) {
  //     case 'jpg':
  //     case 'gif':
  //     case 'bmp':
  //     case 'png':
  //       //etc
  //       return true;
  //   }
  //   return false;
  // }

  // isVideo(filename) {
  //   var ext = this.getExtension(filename);
  //   switch (ext.toLowerCase()) {
  //     case 'm4v':
  //     case 'avi':
  //     case 'mpg':
  //     case 'mp4':
  //       // etc
  //       return true;
  //   }
  //   return false;
  // }

  getExtension(filename) {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  }
  isImage(filename) {


    if (filename.includes(".jpg") || filename.includes(".gif") || filename.includes(".bmp") || filename.includes(".jpeg")  || filename.includes(".png") || filename.includes(".ipa") || filename.includes(".HEIC") || filename.includes(".JPG") || filename.includes(".GIF") || filename.includes(".BMP") || filename.includes(".JPEG") || filename.includes(".PNG") || filename.includes(".IPA") || filename.includes(".heic")) {
      return true
    }
    else  {
      return false;
    }

    // (filename.includes('.m4v') || filename.includes('.avi') || filename.includes('.mpg') || filename.includes('.mp4'))

  }




  playAudio() {
    //   let audio = new Audio();
    //   audio.src = "assets/audio/tune4.mp3";
    //   audio.load();
    //   audio.muted=true;
    //   audio.play();
    //   audio.muted=false;
    //   audio.play();


    // var audio = new Audio();
    // audio.src = 'assets/audio/tune4.mp3';
    // // when the sound has been loaded, execute your code
    // audio.oncanplaythrough = (event) => {
    //   var playedPromise = audio.play();
    //   if (playedPromise) {
    //     playedPromise.catch((e) => {
    //       console.log(e)
    //       if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
    //         console.log(e.name);
    //       }
    //     }).then(() => {
    //       console.log("playing sound !!!");
    //     });
    //   }
    // }
  }


  @Input() public src: string;
  @Input() public autoplay: boolean = false;
  @Input() public showStateLabel: boolean = false;
  public audioStateLabel = 'Audio sample';
  @Input() public volume: number = 1.0; /* 1.0 is loudest */

  @ViewChild('audioElement', { static: false }) public _audioRef:  ElementRef;
  private audio: HTMLMediaElement;



  public pause(): void {
    if (this.audio) {
      this.audio.pause();
      this.audioStateLabel = 'Paused';
    }
  }

  public get paused(): boolean {
    if (this.audio) {
      return this.audio.paused;
    } else {
      return true;
    }
  }

  public play(): void {
    if (this.audio) {
      if (this.audio.readyState >= 2) {
        this.audio.play();
        this.audioStateLabel = 'Playing...'
      }
    }
  }

  public ngAfterViewInit() {

  }



  preview(){
    console.log(this.form)

  }
  display;

  cls1(){
    this.display='none';
  }
  changeName(e) {
    console.log(e.target.value);
  }
}
