import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Gallery } from 'angular-gallery';
import { WishService } from './../wish.service';

@Component({
  selector: 'app-wish',
  templateUrl: './wish.component.html',
  styleUrls: ['./wish.component.scss']
})
export class WishComponent implements OnInit {
  wish: any;
  name: any;
  _files: any;
  key: any;
  finalFiles: any = []
  Images: any = [];


  constructor(private gallery: Gallery, private _service: WishService, private route: ActivatedRoute) {

  }
  showGallery(index: number) {
    let prop = {
      images: this.Images,
      index
    };
    this.gallery.load(prop);
  }

  video(i:any) {
    var elem = document.getElementById(i) as any;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();

    }
  }

  ngOnInit(): void {
    this.key = this.route.snapshot.params.id;
    this._service.getWishListByKey(this.key).subscribe(data => {
      console.log(data)

      this.wish = data[6];
      this.name = data[3];
      this._files = data[1]

      this._files.map(_ => {

        this.Images = [...this.Images, { path: _ }];

      })


      this._files.map(_ => {
        let isImage = this.isImage(_);
        this.finalFiles.push({ isImage, url: _ })

      })
      console.log(this.finalFiles)


    });

  }

  logOut() {
    this._service.SignOut();
  }



  isImage(filename) {


    if (filename.includes(".jpg") || filename.includes(".gif") || filename.includes(".bmp") || filename.includes(".jpeg")  || filename.includes(".png") || filename.includes(".ipa") || filename.includes(".HEIC") || filename.includes(".JPG") || filename.includes(".GIF") || filename.includes(".BMP") || filename.includes(".JPEG") || filename.includes(".PNG") || filename.includes(".IPA") || filename.includes(".heic")) {
      return true
    }
    else {
      return false;
    }

    // (filename.includes('.m4v') || filename.includes('.avi') || filename.includes('.mpg') || filename.includes('.mp4'))

  }


}
