<header class="dash-header">
  <div id="back" routerLink="/index">
    <i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i>
    <span>Home</span>
  </div>

  <div class="bk" (click)=logOut()>
    <i class="fa fa-sign-out" aria-hidden="true"></i>
    <span>Logout</span>
  </div>

  <h1 class="text-center">Welcome to your dashboard</h1>
  <div class="container text-center">
    <!-- Nav pills -->
    <ul class="nav nav-pills justify-content-center" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" (click)="filter($event,'NISCHAY')" data-toggle="pill" >Nischay</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="filter($event,'FAMILY')" data-toggle="pill" >Family</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="filter($event,'FRIENDS')" data-toggle="pill" >Friends</a>
      </li>

    </ul>
  </div>
</header>
<div class="msg">
  <!-- Tab panes -->
  <div class="tab-content">
    <div id="home" class="container tab-pane active">
      <div *ngIf="wishes"><ul>
        <ng-container  *ngFor="let box of wishes">

         <ng-container  *ngIf="box?.isOpen; else close">
           <li (click)="goToWish(box)">
             <div class="each-msg">
               <img src="assets/images/email-open.png" alt="">
               <h4><span>Sent by </span>{{box?.name}}</h4>
               <!-- <p style="
                overflow: hidden;

                display: -webkit-box;

                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            ">{{box?.wish}}</p> -->
             </div>
           </li>
         </ng-container >

         <ng-template #close>
           <li (click)="goToWish(box)">
             <div class="each-msg open" >
               <img src="assets/images/email-close.png" alt="">
               <h4><span>Sent by</span>{{box?.name}}</h4>
               <!-- <p style="
               overflow: hidden;

               display: -webkit-box;

               -webkit-line-clamp: 2;
               -webkit-box-orient: vertical;
           ">{{box?.wish}}</p> -->
             </div>
           </li>
         </ng-template>


        </ng-container>





       </ul></div>
       <!-- <div *ngIf="!wishes">
         No Wish yet
       </div> -->




  </div>
