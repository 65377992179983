<div class="wish-holder">
  <header class="dash-header">

    <h1 class="text-center"></h1>

  </header>
  <div class="container my-5">


    <div class="row yy">
      <div class="col-md-4 col-6 ap" *ngFor="let item of finalFiles;let i=index">

        <img [src]="item.url" *ngIf="item.isImage" (click)="showGallery(i)" [counter]=true />

        <div class="strip" *ngIf="!item.isImage">
          <div class="film">
            <div class="film__frame">

              <video id={{i}} controls (click)="video(i)">
                <source [src]="item.url" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>

          </div>




        </div>
        <!-- <video *ngIf="!item.isImage"  width="400" height="440" id={{i}} controls (click)="video(i)">
          <source [src]="item.url" type="video/mp4">
          Your browser does not support the video tag.
        </video> -->

      </div>


    </div>



    <div class="explanation">










      <div class="wish-text">
        <img src="../../assets/images/wish.png" alt="" id="tp">
        <!-- <h2>Message Heading</h2> -->
        <p>{{wish}}</p>
        <img src="../../assets/images/wish.png" alt="" id="bt">
      </div>
      <div class="sender-info">
        <p>Sent by <span>{{name}}</span></p>
      </div>
    </div>



  </div>
