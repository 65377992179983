<div class="wish-holder">
  <header class="dash-header">
    <div id="back" routerLink="/dashboard">
      <i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i>
      <span>Home</span>
    </div>
    <h1 class="text-center">Here are your messages <br> <p class="text-center" style="font-size: 14px; margin-top: 5px;">Click to view media</p></h1>

    <div (click)=logOut() class="bk">
      <i class="fa fa-sign-out" aria-hidden="true"></i>
      <span>Logout</span>
    </div>
  </header>
  <div class="container my-5">


    <div class="row yy" >
      <div class="col-sm-4 col-6 ap" *ngFor="let item of finalFiles;let i=index" >

        <img [src]="item.url" *ngIf="item.isImage" (click)="showGallery(i)" [counter]=true />

        <div class="strip" *ngIf="!item.isImage">
          <div class="film">
            <div class="film__frame">`

              <video id={{i}} controls (click)="video(i)" style=" height: 100%; width:100%; object-fit: contain; ">
                <source [src]="item.url" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>

          </div>




        </div>
        <!-- <video *ngIf="!item.isImage"  width="400" height="440" id={{i}} controls (click)="video(i)">
          <source [src]="item.url" type="video/mp4">
          Your browser does not support the video tag.
        </video> -->

      </div>


    </div>



    <div class="explanation">










      <div class="wish-text">
        <img src="../../assets/images/wish.png" alt="" id="tp">
        <!-- <h2>Message Heading</h2> -->
        <p>{{wish}}</p>
        <img src="../../assets/images/wish.png" alt="" id="bt">
      </div>
      <div class="sender-info">
        <p>Sent by <span>{{name}}</span></p>
      </div>
    </div>



  </div>
